/** @jsxImportSource @emotion/react */
import React from "react";
import { useTransition, animated } from "react-spring";
import { Routes, Route, useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import Clock from "../containers/Clock";
import Note from "./Note";
// import PrivacyPolicy from "./PrivacyPolicy";
import Footer from "./Footer";
// import Contact from "../containers/Contact";

const Main: React.FC = () => {
  const location = useLocation();
  const transitions = useTransition(location, {
    initial: { display: "block", opacity: 1 },
    from: { display: "none", opacity: 0 },
    enter: { display: "block", opacity: 1 },
    leave: { display: "none", opacity: 0 },
  });

  return (
    <React.Fragment>
      <div css={main}>
        {transitions(
          (props, item) =>
            item && (
              <animated.div style={props}>
                <Routes location={item}>
                  <Route path="/" Component={Clock} />
                  <Route path="/note" Component={Note} />
                  {/* <Route path="/privacy-policy" Component={PrivacyPolicy} /> */}
                  {/* <Route path="/contact" component={Contact} /> */}
                </Routes>
              </animated.div>
            )
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

const main = css`
  max-width: 760px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  padding: 1rem 5%;
`;

export default Main;
