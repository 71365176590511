export default {
  blue: "#0f4c81",
  lightBlue: "#3774a9",
  moreLightBlue: "#4b88bd",
  darkBlue: "#00050c",
  orange: "#fd6a02",
  lightOrange: "#ff7e16",
  moreLightOrange: "#ff922a",
  mostLightOrange: "#ffa63e",
  darkOrange: "#e95600",
  moreDarkOrange: "#d54200",
  white: "#f0ece3",
  stars: "#f0ece3",
  stars2: "#c8c4bb",
  stars3: "#969289",
  black: "#363838"
};
